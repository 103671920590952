import React from 'react'
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const styles = ({ breakpoints }: any) =>
  createStyles({
    title: {
      marginBottom: 40,
    },
    box: {
      maxWidth: 400,
      padding: 60,
      margin: 'auto',
      [breakpoints.down('sm')]: {
        padding: 20,
      },
    },
  })

interface Props extends WithStyles<typeof styles> {
  title: string
  children: React.ReactNode
}

const Box = ({ classes, title, children, ...props }: Props) => (
  <Paper className={classes.box} elevation={1} square {...props}>
    <Typography className={classes.title} variant="h2">
      {title}
    </Typography>
    {children}
  </Paper>
)

export default withStyles(styles)(Box)
